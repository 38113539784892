body {
    font-family: 'Roboto', sans-serif;
  }
  
  .template-page {
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .receipt-wrapper {
    max-width: 340px;
    margin: 30px auto;
    padding: 30px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-head, .invoice-body, .invoice-foot {
    margin-bottom: 20px;
  }
  
  .invoice-head-top-left img {
    max-width: 130px;
  }
  
  .invoice-head-middle, .invoice-head-bottom {
    padding: 16px 0;
  }
  
  .invoice-body table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-body table th, .invoice-body table td {
    padding: 12px;
  }
  
  .invoice-body table th {
    background-color: #153a42;
    color: #fff;
  }
  
  .invoice-body table td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .invoice-body-info-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
  }
  
  .invoice-foot {
    padding: 30px 0;
    font-size: 12px;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
  }
  
  .invoice-head-bottom-left,
  .invoice-head-bottom-right {
    display: flex;
    flex-direction: column;
  }
  
  .invoice-head-bottom {
    display: flex;
    justify-content: space-between;
  }
  
  .text-start {
    text-align: left;
  }
  
  .text-end {
    text-align: right;
  }
  
  .text-bold {
    font-weight: bold;
  }
  
  .text-white{
    color: white;
  }