/* Dashboard.css */
.dashboard-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  .api-keys {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .api-key {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .api-key h3 {
    margin-bottom: 10px;
  }
  
  .api-key input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .api-key button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .api-key button:disabled {
    background: #cccccc;
  }
  
  .integration-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .square-integration button {
    margin: 5px;
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .square-integration button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  