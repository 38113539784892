/* src/components/Login.css */
.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    margin-bottom: 20px;
    color: #153a42;
  }
  
  .login-container h3 {
    margin-top: 20px;
    color: #666;
  }
  
  .login-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    background-color: #153a42;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .login-button:disabled {
    background-color: #153a42;
    cursor: not-allowed;
  }
  
  .login-button:hover:not(:disabled) {
    background-color: #153a42;
  }
  
  #recaptcha-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  .login-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #153a42;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-button:hover {
    background-color: #153a42;
  }
  
  .login-button[disabled] {
    background-color: #153a42;
    cursor: not-allowed;
  }